import { Dispatch, SetStateAction, useState } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  Box,
  VStack,
  Center,
  HStack,
  Stack,
  ModalCloseButton,
  Input,
} from '@chakra-ui/react'
import { CoreRadio } from 'components/core-ui-controls/RadioButton'
import { convertShippingToCTAddress } from 'composable/helpers/utils/checkout-utils'
import { CheckoutAddressType, parseCheckoutAddressToString } from 'composable/helpers/utils/experian-address-utils'
import { useFormat } from 'helpers/hooks/useFormat'
import { ExperianAddressType, formatExperianAddress } from 'frontastic/actions/experian'
import { AppartmentInfo, PickListItem, QasRecommendedAddress, VerifyLevel } from './types/Account.d'
interface QasModalProps {
  modalOpen: boolean
  modalOnClose: () => void
  enteredAddress?: CheckoutAddressType
  recommendedAddress?: QasRecommendedAddress
  handleSetAppartmentInfo: (values: AppartmentInfo) => void
  verifyLabel: VerifyLevel
  handleStep1Submit: Function
  setAddressConfirmationDecision: Dispatch<SetStateAction<boolean>>
  setAddressConfirmationModal: Dispatch<SetStateAction<boolean>>
  modalTitle?: string
  hideEditButton?: boolean
  hideApartmentNumberInputBox?: boolean
}

enum ISelectedAddressType {
  recommended = 'recommended',
  youEntered = 'youEntered',
  addrLineItem = 'addrLineItem',
}
interface ISelectedAddress {
  type: ISelectedAddressType
  index: number
}

const QasModal = ({
  modalOpen,
  modalOnClose,
  enteredAddress,
  recommendedAddress,
  handleSetAppartmentInfo,
  verifyLabel = VerifyLevel.MULTIPLE,
  handleStep1Submit,
  setAddressConfirmationDecision,
  setAddressConfirmationModal,
  modalTitle,
  hideEditButton = false,
  hideApartmentNumberInputBox = false,
}: QasModalProps) => {
  const [selectedAddress, setSelectedAddress] = useState<ISelectedAddress>({
    type: ISelectedAddressType.youEntered,
    index: -1,
  })
  const [aptStreetNumber, setAptStreetNumber] = useState<string>('')
  const { formatMessage } = useFormat({ name: 'common' })
  const parsedCheckoutAddress = parseCheckoutAddressToString({
    ...enteredAddress,
    additional_address_info: enteredAddress?.additional_street_info ? enteredAddress?.additional_street_info : null,
  })
  const handleFormatAddressData = (formattedAddress: ExperianAddressType) => {
    if (!formattedAddress) return
    return {
      street_name: formattedAddress.address_line_1,
      additional_street_info: formattedAddress.address_line_2,
      city: formattedAddress.locality,
      state: formattedAddress.region,
      country: formattedAddress.country,
      postcode: formattedAddress.postal_code,
    }
  }
  const handleAptStreetChange = (e: any) => {
    setAptStreetNumber(e.target.value)
  }

  const handleStreetAptSubmit = () => {
    if (aptStreetNumber?.length) {
      handleSetAppartmentInfo({ additionalStreetInfo: aptStreetNumber })
      setAptStreetNumber('')
    }
  }
  const handleUseSelectedAddress = async (
    experianAddress: ExperianAddressType,
    extraParameter: {
      useAddressAsEntered: boolean
      addressValidated: boolean
    },
  ) => {
    const updatedAddress = handleFormatAddressData(experianAddress)
    const overwriteAddress = updatedAddress && convertShippingToCTAddress(updatedAddress)

    try {
      handleStep1Submit({ ...overwriteAddress, ...extraParameter })
    } catch (e) {
      console.error('Error updating address from Address Suggestion Modal', e)
    } finally {
      setAddressConfirmationModal(false)
      setAddressConfirmationDecision(true)
    }
  }
  const handleSelectedAddress = (selectedAddress: PickListItem) => async () => {
    if (selectedAddress?.global_address_key) {
      const res = await formatExperianAddress(selectedAddress?.global_address_key)
      if (res?.confidence === VerifyLevel.VERIFIED) {
        let extraParameter = {
          useAddressAsEntered: false,
          addressValidated: true,
        }
        handleUseSelectedAddress({ ...res, postal_code: res?.postal_code_extended }, extraParameter)
      }
    }
  }

  const handleFormEdit = () => {
    modalOnClose()
  }

  const convertToLineItemAddress = (address: CheckoutAddressType) => {
    const addr: ExperianAddressType = {
      address_line_1: address.street_name,
      address_line_2: address?.apartment || address?.additional_street_info || '',
      address_line_3: '',
      locality: address.city,
      region: address.state,
      postal_code: address.postcode,
      country: address?.country,
    }
    return addr
  }
  const parsedCheckoutAddress2 =
    parseCheckoutAddressToString(
      recommendedAddress?.addrLineItem?.address ? recommendedAddress?.addrLineItem?.address : (null as any),
    ) || ''
  const renderVerifiedAddress = () => {
    return (
      <VStack flex={1} gap={3}>
        <Text textStyle={'heading-desktop-75'} w={'full'} textAlign={'left'} fontWeight={600}>
          {formatMessage({
            id: 'checkout.step1.validationModal.recommended',
          })}
        </Text>
        {hasVerifiedLevel && !hideApartmentNumberInputBox && (
          <VStack width={'full'} gap={3}>
            <Input
              value={aptStreetNumber}
              placeholder={formatMessage({
                id: 'addressConfirmation.modal.input.placeholder',
                values: {
                  addressLineType: verifyLabel == VerifyLevel.PREMISEPARTIAL ? 'Apartment' : 'Street',
                },
              })}
              variant={'unstyled'}
              borderRadius={'md'}
              px={4}
              py={2}
              border={'1px solid'}
              textStyle={'body-100'}
              width={'100%'}
              borderColor={'#C4CAD2'}
              outline={0}
              _focus={{ borderColor: 'text.primary', borderWidth: 2, boxShadow: 'none' }}
              onChange={handleAptStreetChange}
            />

            <Button
              variant="solid"
              borderRadius={'md'}
              height={'37px'}
              textStyle={'ui-button-100'}
              py={2.5}
              px={4}
              flex={1}
              onClick={handleStreetAptSubmit}
              width={'full'}
              my={2}
              textTransform={'capitalize'}
            >
              {formatMessage({
                id: 'addressConfirmation.modal.button.confirm',
              }).toLowerCase()}
            </Button>
          </VStack>
        )}
        {recommendedAddress?.pickListItem?.length && (
          <VStack flex={1} width={'fulll'} gap={2.5}>
            {recommendedAddress?.pickListItem?.map((address, index) => {
              let parsedCheckoutAddress = address?.text
              return (
                <HStack
                  p={4}
                  alignItems="center"
                  border={
                    selectedAddress.type === 'recommended' && selectedAddress.index === index
                      ? '2px solid'
                      : '1px solid'
                  }
                  borderRadius="base"
                  cursor="pointer"
                  borderColor={
                    selectedAddress.type === 'recommended' && selectedAddress.index === index
                      ? 'primary.text'
                      : 'surface.border'
                  }
                  onClick={() => handleReccomendedAddressSelected({ type: ISelectedAddressType.recommended, index })}
                  flex={1}
                  key={index + address.postal_code}
                  width={'full'}
                >
                  <CoreRadio
                    value={'recommended-' + { index }}
                    size="md"
                    onClick={() => handleReccomendedAddressSelected({ type: ISelectedAddressType.recommended, index })}
                    isChecked={selectedAddress.type === `recommended` && selectedAddress.index === index}
                  />
                  <VStack flex={1} alignItems="flex-start">
                    <Text textStyle={'body-75'}>{parsedCheckoutAddress}</Text>
                  </VStack>
                </HStack>
              )
            })}
          </VStack>
        )}
        {!recommendedAddress?.pickListItem?.length && recommendedAddress?.addrLineItem?.address && (
          <HStack
            p={4}
            alignItems="center"
            border="1px solid"
            borderRadius="base"
            cursor="pointer"
            borderColor={selectedAddress.type === ISelectedAddressType.addrLineItem ? 'primary.text' : 'surface.border'}
            onClick={() => handleReccomendedAddressSelected({ type: ISelectedAddressType.addrLineItem, index: -1 })}
            flex={1}
            width={'full'}
          >
            <CoreRadio
              value={'-'}
              size="md"
              onClick={() => handleReccomendedAddressSelected({ type: ISelectedAddressType.addrLineItem, index: -1 })}
              isChecked={selectedAddress.type === ISelectedAddressType.addrLineItem}
            />
            <VStack flex={1} alignItems="flex-start">
              <Text textStyle={'body-75'}>{parsedCheckoutAddress2}</Text>
            </VStack>
          </HStack>
        )}
      </VStack>
    )
  }
  const handleSubmit = (selectedAddress: ISelectedAddress) => {
    if (selectedAddress.type === 'youEntered')
      handleUseSelectedAddress(convertToLineItemAddress(enteredAddress), {
        useAddressAsEntered: true,
        addressValidated: false,
      })
    else if (selectedAddress.type === ISelectedAddressType.addrLineItem) {
      handleUseSelectedAddress(
        convertToLineItemAddress(recommendedAddress.addrLineItem?.address as CheckoutAddressType),
        {
          useAddressAsEntered: false,
          addressValidated: true,
        },
      )
    } else {
      handleSelectedAddress(recommendedAddress.pickListItem[selectedAddress.index] as any)()
    }
  }
  const handleReccomendedAddressSelected = (selectedAddress: ISelectedAddress) => {
    setSelectedAddress(selectedAddress)
    if (selectedAddress.type === ISelectedAddressType.youEntered) return
    handleSubmit(selectedAddress)
  }
  const hasVerifiedLevel =
    verifyLabel === VerifyLevel.STREETPARTIAL ||
    verifyLabel === VerifyLevel.INTERACTIONREQUIRED ||
    verifyLabel === VerifyLevel.PREMISEPARTIAL ||
    verifyLabel === VerifyLevel.MULTIPLE
  return (
    <Modal
      isOpen={modalOpen}
      onClose={modalOnClose}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      size={{ base: 'xs', lg: 'sm' }}
    >
      <ModalOverlay />
      <ModalContent>
        <VStack width="100%" spacing={0}>
          <Box width="100%" borderBottom="1px solid" borderBottomColor="surface.border" py={2}>
            <Center minH={8}>
              <Text textStyle={'heading-desktop-100'} textAlign="center" fontWeight={600}>
                {modalTitle ??
                  formatMessage({
                    id: 'checkout.step1.validationModal.title',
                  })}
              </Text>
              <ModalCloseButton />
            </Center>
          </Box>

          <VStack width="100%" py={6} px={{ base: 4, md: 6 }} gap={6}>
            <Text width="100%" textStyle={'body-75'} color="text.primary" textAlign="left">
              {formatMessage({
                id: 'checkout.step1.validationModal.message',
              })}
            </Text>
            <Stack width="100%" direction={{ base: 'column', lg: 'row' }} gap={4}>
              <VStack flex={1} gap={3}>
                <Text textStyle={'heading-desktop-75'} textAlign={'left'} width={'100%'} fontWeight={600}>
                  {formatMessage({
                    id: 'checkout.step1.validationModal.entered',
                  })}
                </Text>
                <HStack
                  width={'100%'}
                  p={4}
                  alignItems="center"
                  border="2px solid"
                  borderRadius="base"
                  cursor="pointer"
                  borderColor={selectedAddress.type === 'youEntered' ? 'primary.text' : 'surface.border'}
                  onClick={() => setSelectedAddress({ type: ISelectedAddressType.youEntered, index: -1 })}
                >
                  <CoreRadio
                    value="youEntered"
                    size="md"
                    onClick={() => setSelectedAddress({ type: ISelectedAddressType.youEntered, index: -1 })}
                    isChecked={selectedAddress.type === 'youEntered'}
                  />
                  <VStack flex={1} alignItems="flex-start">
                    <Text textStyle={'body-75'}>{parsedCheckoutAddress}</Text>
                  </VStack>
                </HStack>
                <VStack py={2} flex={1} width={'full'} gap={2}>
                  {!hideEditButton && (
                    <Button
                      variant="solid"
                      width={'full'}
                      textStyle={'ui-button-100'}
                      height={'37px'}
                      py={2.5}
                      px={4}
                      onClick={handleFormEdit}
                    >
                      {formatMessage({
                        id: 'checkout.step1.validationModal.action.editAddress',
                      })}
                    </Button>
                  )}
                  <Button
                    variant="outline"
                    width={'full'}
                    textStyle={'ui-button-100'}
                    py={2.5}
                    px={4}
                    height={'37px'}
                    isDisabled={selectedAddress.type !== ISelectedAddressType.youEntered}
                    onClick={() => handleSubmit(selectedAddress)}
                  >
                    {formatMessage({
                      id: 'checkout.step1.validationModal.action.useThisAddress',
                    })}
                  </Button>
                </VStack>
              </VStack>
              {hasVerifiedLevel &&
                recommendedAddress &&
                (recommendedAddress?.pickListItem?.length || recommendedAddress?.addrLineItem?.address) &&
                renderVerifiedAddress()}
            </Stack>
          </VStack>
        </VStack>
      </ModalContent>
    </Modal>
  )
}

export default QasModal
