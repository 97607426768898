import { Address, CartAddress } from '@Types/account/Address'
import { Cart } from '@Types/cart/Cart'
import { ADDRESS_TYPE } from 'composable/components/checkout/constants'
import { getAddressType } from 'composable/components/checkout/step1/utils'
import { FIND_MINE_APPLICATION_ID } from 'composable/components/general/constants'
import { CheckoutStateType, GiftCardOrRewardCertificatePayment } from 'composable/components/types'
import CryptoJS from 'crypto-js'
import { v4 as uuidv4 } from 'uuid'
import { ExperianAddressType } from './experian-address-utils'
import { PAYMENT_METHOD_CREDITCART } from '../constants/checkout'
declare const window
export const getCheckoutAddress = (ctAddress?: Address) => {
  return {
    additional_street_info: ctAddress?.additionalStreetInfo ?? '',
    additional_address_info: ctAddress?.additionalAddressInfo ?? '',
    city: ctAddress?.city ?? '',
    country: ctAddress?.country ?? '',
    first_name: ctAddress?.firstName ?? '',
    id: ctAddress?.addressId ?? 'new',
    last_name: ctAddress?.lastName ?? '',
    phone_number: ctAddress?.phone ?? '',
    postcode: ctAddress?.postalCode ?? '',
    state: ctAddress?.state ?? '',
    street_name: ctAddress?.streetName ?? '',
    street_number: ctAddress?.streetNumber ?? '',
    apartment: ctAddress?.apartment ?? '',
    useAddressAsEntered: ctAddress?.custom?.fields?.useAddressAsEntered || ctAddress?.useAddressAsEntered || false,
    addressValidated: ctAddress?.custom?.fields?.addressValidated || ctAddress?.addressValidated || false,
  }
}

export const ctAddressRefinement = (address: any) => {
  return {
    streetName: address?.street_name || '',
    additionalStreetInfo: address?.additional_street_info || '',
    city: address?.city || '',
    postalCode: address?.postcode,
    state: address?.state,
  }
}
export const convertShippingToCTAddress = (address: Partial<CheckoutStateType['shipping_address']>): CartAddress => {
  return {
    addressId: uuidv4(),
    additionalStreetInfo: address.additional_street_info,
    additionalAddressInfo: getAddressType(address),
    city: address.city,
    country: address.country,
    firstName: address.first_name,
    lastName: address.last_name,
    phone: address.phone_number,
    postalCode: address.postcode,
    state: address.state,
    streetName: address.street_name,
    streetNumber: address.street_number,
    apartment: address.apartment,
    useAddressAsEntered: address?.useAddressAsEntered ?? false,
    addressValidated: getAddressType(address) === ADDRESS_TYPE.SHIP_TO_STORE ?? address?.addressValidated,
  }
}
export const ctAddressToExperianAddress = (address: CartAddress): ExperianAddressType => {
  return {
    address_line_1: address?.streetName,
    address_line_2: address?.additionalStreetInfo || address?.apartment || '',
    locality: address?.city,
    region: address?.state,
    postal_code: address?.postalCode,
    country: address?.country,
    phone: address?.phone,
    address_line_3: '',
    additional_address_info: address?.additionalAddressInfo,
    additional_street_info: address?.additionalStreetInfo,
  }
}

export const convertBillingToCTAddress = (address: CheckoutStateType['billing_address']): CartAddress => {
  return {
    addressId: address.id,
    city: address.city,
    country: address.country,
    firstName: address.first_name,
    lastName: address.last_name,
    postalCode: address.postcode,
    state: address.state,
    streetName: address.street_name,
    streetNumber: address.street_number,
    phone: address.phone_number,
    apartment: address.apartment,
    useAddressAsEntered: address?.useAddressAsEntered ?? false,
    addressValidated: address?.addressValidated ?? false,
  }
}

export const shippingAddressInSync = (
  cart: Cart,
  shipping_address: CheckoutStateType['shipping_address'] | undefined,
): boolean => {
  if (!cart || !cart.shippingAddress?.addressId || !shipping_address) return false
  const shippingAddress = cart.shippingAddress ?? {} // extract the relevant properties from cart?.shippingAddress

  return (
    shippingAddress.addressId === shipping_address.id &&
    shippingAddress.additionalAddressInfo === shipping_address.additional_address_info &&
    shippingAddress.firstName === shipping_address.first_name &&
    shippingAddress.lastName === shipping_address.last_name &&
    shippingAddress.phone === shipping_address.phone_number &&
    shippingAddress.streetName === shipping_address.street_name &&
    shippingAddress.city === shipping_address.city &&
    shippingAddress.state === shipping_address.state &&
    shippingAddress.postalCode === shipping_address.postcode &&
    shippingAddress.country === shipping_address.country
  ) // compare each field of the extracted shippingAddress object with the corresponding field in shipping_address
}

export const isBillingDifferentThanShipping = (shipping_address: Address, billing_address: Address) => {
  if (!shipping_address || !billing_address) return false

  if (
    !billing_address.firstName ||
    !billing_address.lastName ||
    !billing_address.streetName ||
    !billing_address.city ||
    !billing_address.state ||
    !billing_address.postalCode
  ) {
    return false
  }

  return (
    shipping_address.firstName !== billing_address.firstName ||
    shipping_address.lastName !== billing_address.lastName ||
    shipping_address.streetName !== billing_address.streetName ||
    shipping_address.city !== billing_address.city ||
    shipping_address.state !== billing_address.state ||
    shipping_address.postalCode !== billing_address.postalCode
  )
}

export const getSelectedPaymentMethod = (type: string) => {
  let cardPaymentString = `^(${PAYMENT_METHOD_CREDITCART}|CARDPAYMENT)$`
  let cardPaymentRegex = new RegExp(cardPaymentString, 'i')
  if (cardPaymentRegex.test(type)) {
    return PAYMENT_METHOD_CREDITCART
  }
  return type
}

export const getCreditcardPaymentPayload = (creditCardPayment: any, isLoggedIn: boolean) => {
  if (isLoggedIn && creditCardPayment?.card) {
    return {
      card: {
        cardType: creditCardPayment.card.cardType,
        nameOnCard: creditCardPayment.card.nameOnCard,
        cardNumber: creditCardPayment.card.cardNumber,
        cardExpirationMonth: creditCardPayment.card.expMonth,
        cardExpirationYear: creditCardPayment.card.expYear,
        isStoredCredentialsPolicyAck: false,
        isDefaultCard: false,
        cscCode: creditCardPayment.cvv,
        saveCardToAccount: creditCardPayment?.card.saveCardToAccount ?? false,
      },
      customerCardToken: creditCardPayment.card.token,
    }
  }

  return {
    card: {
      cardType: creditCardPayment.cardType,
      nameOnCard: creditCardPayment.creditCardUserName,
      cardNumber: creditCardPayment.creditCardNumber,
      cardExpirationMonth: creditCardPayment.expiryDate?.split('/')[0],
      cardExpirationYear: creditCardPayment.expiryDate?.split('/')[1],
      isStoredCredentialsPolicyAck: false,
      isDefaultCard: false,
      cscCode: creditCardPayment.securityCode,
      saveCardToAccount: creditCardPayment.saveCardToAccount ?? false,
    },
  }
}

export function decodeJWT(token) {
  const parts = token.split('.')
  if (parts.length !== 3) {
    throw new Error('Invalid JWT format')
  }

  // Decode Base64 URL-encoded Header and Payload
  const header = JSON.parse(atob(parts[0]))
  const payload = JSON.parse(atob(parts[1]))

  return { header, payload }
}

export const isAlreadyGiftCardORCertificate = (gcOrRc: GiftCardOrRewardCertificatePayment[], cardNumber: string) => {
  return !!gcOrRc.filter((card) => card.giftCardNumber?.trim() === cardNumber?.trim())?.length
}

export const getSelectedShippingMethod = (cart: Cart) => {
  const selectedShippingMethod = cart?.availableShippingMethods?.find(
    (method) => method.shippingMethodId === cart?.shippingInfo?.shippingMethodId,
  )

  return selectedShippingMethod
}

export const sendFindMineTransactionData = (data: Cart, email: string) => {
  const hashEmail = CryptoJS.MD5(email)
  const items = []
  data.lineItems?.forEach((item) => {
    const product_price = item?.price?.centAmount || 0
    const discountedPrice: string | number = item?.discountedPrice?.centAmount || product_price || 0
    const obj = {
      product_id: item?.variant?.attributes?.itemNumber,
      product_sku: item?.variant?.sku,
      product_quantity: item?.count,
      product_price: (product_price / 100).toFixed(2),
      product_list_price: (discountedPrice / 100).toFixed(2),
    }
    items.push(obj)
  })
  const totalPrice: any = data?.totalTaxOncart?.totalNet?.centAmount || 0
  const totalDiscount: any = data?.totalDiscountOnTotalPrice || 0
  const params = {
    application: FIND_MINE_APPLICATION_ID,
    transaction_id: data?.orderNumber,
    total_price: (totalPrice / 100).toFixed(2),
    total_discount: (totalDiscount / 100).toFixed(2),
    customer_id: hashEmail,
    items: items,
  }
  window?.FindMine?.transaction(params)
}

export function getBrowserCookie(cookieName: string) {
  if (typeof document !== 'object' || typeof document.cookie !== 'string') {
    return undefined
  }
  const name = cookieName + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return undefined
}
