import { textStyles as _textStyles } from '../figma-tokens'
import { mergeTextStyles } from '../utils/mergeTextStyles'

// For key/props ref: https://v0.chakra-ui.com/style-props
export const responsiveTextStyles = {
  /* For responsive text styles
   * 1. you can use the `mergeTextStyles`
   * util to Combine textStyles
   * 2. do just use `textStyle={{ base: 'heading-mobile-400', lg: 'heading-desktop-400' }}`
   * inline in your component
   */
  'body-300-500': mergeTextStyles({
    baseStyle: _textStyles['body-100'],
    lgStyle: _textStyles['body-300'],
  }),
  'body-100-300': mergeTextStyles({
    baseStyle: _textStyles['body-100'],
    lgStyle: _textStyles['body-300'],
  }),
  'callouts-blockquote-75-100': mergeTextStyles({
    baseStyle: _textStyles['callouts-blockquote-75'],
    lgStyle: _textStyles['callouts-blockquote-100'],
  }),
  'callouts.blockquote': mergeTextStyles({
    baseStyle: _textStyles['callouts-blockquote-50'],
    lgStyle: _textStyles['callouts-blockquote-75'],
  }),
  'body-25-50': mergeTextStyles({
    baseStyle: _textStyles['body-25'],
    lgStyle: _textStyles['body-50'],
  }),
  eyebrow: mergeTextStyles({
    baseStyle: _textStyles['eyebrow-50'],
    lgStyle: _textStyles['eyebrow-75'],
  }),
}

export const textStyles = { ...responsiveTextStyles, ..._textStyles }
