import { Account } from '@Types/account/Account'
import { Address } from '@Types/account/Address'
import { Cards } from '@Types/account/Cards'
import { mutate, SWRResponse } from 'swr'
import { AuthZeroAccessToken } from 'frontastic/contexts/authV2UserContext/types'
import { fetchApiHub, ResponseError } from 'frontastic/lib/fetch-api-hub'

export interface GetAccountResult extends Omit<SWRResponse<Account>, 'data' | 'mutate'> {
  loggedIn: boolean
  account?: Account
  error: ResponseError
}

export interface UpdateAccount {
  firstName?: string
  lastName?: string
  salutation?: string
  birthdayYear?: number
  birthdayMonth?: number
  birthdayDay?: number
}

export interface RegisterAccount extends UpdateAccount {
  email: string
  password: string
  billingAddress?: Address
  shippingAddress?: Address
}

export const getAccount = async (): Promise<GetAccountResult> => {
  try {
    const res = await fetchApiHub(
      '/action/account/getAccount',
      {
        method: 'POST',
      },
      {},
    )
    mutate('/action/account/getAccount', res, { revalidate: false })
    if (res?.account) {
      return {
        account: res?.account,
        error: res?.error,
        isValidating: res?.isValidating,
        isLoading: res?.isLoading,
        loggedIn: true,
      }
    }
    return {
      account: undefined,
      error: res.error,
      isValidating: res?.isValidating,
      isLoading: res?.isLoading,
      loggedIn: false,
    }
  } catch (error) {
    console.error('Error API: getAccount', error)
  }
}

export const login = async (accessToken: AuthZeroAccessToken): Promise<Account> => {
  const res = await fetchApiHub('/action/account/loginCT', { method: 'POST' }, { accessToken })
  const accountResponse = {
    account: res?.userAccount,
    error: res?.error,
    isValidating: res?.isValidating ?? false,
    isLoading: res?.isLoading ?? false,
    loggedIn: true,
  }
  await mutate('/action/account/getAccount', accountResponse, { revalidate: false })
  await mutate('/action/cart/getCart')
  return res
}

export const logout = async () => {
  const res = await fetchApiHub('/action/account/logout', { method: 'POST' })

  await mutate(() => true, undefined, { revalidate: false })
  return res
}

export const register = async (account: RegisterAccount): Promise<Account> => {
  const response = await fetchApiHub('/action/account/register', { method: 'POST' }, account)
  return response
}

export const confirm = async (token: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/confirm', { method: 'POST' }, { token })
  await mutate('/action/account/getAccount', res)
  return res
}

export const requestConfirmationEmail = async (email: string, password: string): Promise<void> => {
  const payload = {
    email,
    password,
  }
  const res = await fetchApiHub('/action/account/requestConfirmationEmail', { method: 'POST' }, payload)
  return res
}

export const changePassword = async (oldPassword: string, newPassword: string): Promise<Account> => {
  return await fetchApiHub('/action/account/password', { method: 'POST' }, { oldPassword, newPassword })
}

export const requestPasswordReset = async (email: string): Promise<void> => {
  const payload = {
    email,
  }

  return await fetchApiHub('/action/account/requestReset', { method: 'POST' }, payload)
}

export const resetPassword = async (token: string, newPassword: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/reset', { method: 'POST' }, { token, newPassword })
  await mutate('/action/account/getAccount', res)
  return res
}

export const update = async (account: UpdateAccount): Promise<Account> => {
  const res = await fetchApiHub('/action/account/update', { method: 'POST' }, account)
  await mutate('/action/account/getAccount', res)
  return res
}

export const addAddress = async (address: Omit<Address, 'addressId'>): Promise<Account> => {
  const res = await fetchApiHub('/action/account/addAddress', { method: 'POST' }, address)
  await mutate('/action/account/getAccount', res)
  return res
}

export const updateAddress = async (address: Address): Promise<Account> => {
  const res = await fetchApiHub('/action/account/updateAddress', { method: 'POST' }, address)
  await mutate('/action/account/getAccount', res)
  return res
}

export const removeAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/removeAddress', { method: 'POST' }, { addressId })
  await mutate('/action/account/getAccount', res)
  return res
}

export const setDefaultBillingAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/setDefaultBillingAddress', { method: 'POST' }, { addressId })
  await mutate('/action/account/getAccount', res)
  return res
}

export const setDefaultShippingAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/setDefaultShippingAddress', { method: 'POST' }, { addressId })
  await mutate('/action/account/getAccount', res)
  return res
}

export const updateCard = async (card: Cards): Promise<Account> => {
  const res = await fetchApiHub('/action/account/updateCard', { method: 'POST' }, { card })
  await mutate('/action/account/getAccount', res)
  return res
}
export const deleteCard = async (card: Cards): Promise<Account> => {
  const res = await fetchApiHub('/action/account/deleteCard', { method: 'POST' }, { card })
  await mutate('/action/account/getAccount', res)
  return res
}

export const markCardDefault = async (card: Cards): Promise<Account> => {
  const res = await fetchApiHub('/action/account/markCardAsDefault', { method: 'POST' }, { card })
  await mutate('/action/account/getAccount', res)
  return res
}
