import { Address } from '@Types/account/Address'
import { Cart } from '@Types/cart/Cart'
import { LineItem } from '@Types/cart/LineItem'
import { Order } from '@Types/cart/Order'
import { getPhoneformat } from 'composable/components/account/pages/account-dashboard/components/address-form'
import { SHIPPING_METHODS } from 'composable/components/general/constants'
import { dollarsToCents } from 'helpers/price-conversion'
import { AFTERPAY_MAX_CARTVALUE_IN_CENT, AFTERPAY_MIN_CARTVALUE_IN_CENT } from '../constants/checkout'
import { CALIFORNIA_STATE_CODE, HANDLING_FEES_TIERS } from '../constants/handling-fees-tiers'

/* Cart Utils */

export const getCartSummaryAmounts = (cart: Cart | Order, giftCardTotal = 0) => {
  const { shippingPrice, discountedShippingPrice, isShippingMethodSelected } = getCartShippingInfo(cart)
  const { cartDiscountsTotal, promoDiscount, cartDiscountsNames, promoDiscountName } = calculateCartDiscounts(cart)
  const { subtotal, cartSum, totalDiscountOnTotalPrice } = calculateCartTotalPriceAttributes(cart)

  const taxes = calculateCartTaxes(cart)
  const handlingFees = getHandlingFeesFromCart(cart)
  const coRetailDeliveryFee = getColoradoShippingFee(cart)
  const promoCodeLoyaltyTier = getPromoCodeLoyaltyTierFromCart(cart)
  const totalAfterGc = getTotalAfterGiftCard(cartSum, taxes, coRetailDeliveryFee, giftCardTotal)
  const isBopisOnlyCart = checkIsBopisOnlyCart(cart)
  return {
    subtotal,
    taxes,
    coRetailDeliveryFee,
    shippingPrice,
    discountedShippingPrice,
    isShippingMethodSelected,
    handlingFees,
    promoCodeLoyaltyTier,
    cartDiscountsTotal,
    promoDiscount,
    cartDiscountsNames,
    promoDiscountName,
    totalDiscountOnTotalPrice,
    totalAfterGc,
    isBopisOnlyCart,
    total: (cartSum || 0) + (taxes || 0) + (coRetailDeliveryFee || 0), // shipping + handling fees is included in cartSum
  }
}

export const getTotalAfterGiftCard = (
  cartSum: number,
  taxes: number,
  coRetailDeliveryFee: number,
  giftCardTotal: number,
) => {
  const giftGiftCardInCent = dollarsToCents(giftCardTotal) // Convert dollars to cents
  let totalAfterGc = (cartSum || 0) + (taxes || 0) + (coRetailDeliveryFee || 0) - giftGiftCardInCent
  return totalAfterGc > 0 ? totalAfterGc : 0 // Ensure totalAfterGc is not negative
}

export const calculateCartTotalPriceAttributes = (cart: Cart) => {
  const subtotal = cart?.subTotal
  const cartSum = cart?.sum?.centAmount
  const totalDiscountOnTotalPrice = cart?.totalDiscountOnTotalPrice || 0

  return { subtotal, cartSum, totalDiscountOnTotalPrice }
}

export const calculateCartSubtotal = (cart: Cart) => {
  const cartSubtotal = cart?.lineItems?.reduce((accumulator, current) => {
    const price = current?.price?.centAmount
    const discountedPrice = current?.discountedPrice?.centAmount
    const { isDiscounted } = getLineItemAmounts(current as LineItem)
    const currentTotal = (isDiscounted ? discountedPrice : price) * current.count
    return accumulator + currentTotal
  }, 0)
  return cartSubtotal
}

export const calculateCartDiscounts = (cart: Cart) => {
  if (!cart || !cart?.cartDiscounts?.length) {
    return {
      cartDiscountsTotal: 0,
      cartDiscountsNames: [],
      promoDiscount: 0,
      promoDiscountName: [],
    }
  }

  let cartDiscountsTotal = 0
  let cartDiscountsNames = []
  let promoDiscount = 0 // There can be only 1 promo discount applied at a time
  let promoDiscountName = [] // There can be only 1 promo discount applied at a time

  cart?.cartDiscounts?.forEach((discount) => {
    const discountValue = discount?.value
    const discountName = discount?.name
    const isPromoDiscount = discount?.isAppliedViaPromoCode

    if (isPromoDiscount) {
      promoDiscount = discountValue
      promoDiscountName.push({ discountName, discountValue })
    } else {
      cartDiscountsTotal += discountValue
      cartDiscountsNames.push({ discountName, discountValue })
    }
  })

  return {
    cartDiscountsTotal,
    cartDiscountsNames,
    promoDiscount,
    promoDiscountName,
  }
}

export const calculateCartTaxes = (cart: Cart) => {
  const coloradoRetailDeliveryFee = cart?.custom?.fields?.coloradoRetailDeliveryFee || 0

  if (coloradoRetailDeliveryFee > 0) {
    const taxWithoutColoradoTax = cart?.totalTaxOncart?.totalTax?.centAmount / 100 - Number(coloradoRetailDeliveryFee)
    return taxWithoutColoradoTax * 100
  }

  return cart?.totalTaxOncart?.totalTax?.centAmount ?? undefined
}

export const checkIsBopisOnlyCart = (cart: Cart) => {
  return cart?.lineItems
    ?.filter((item) => String(item.variant.key) !== 'item2630' && String(item.variant.key) !== 'item2630-1')
    .every((item) => item?.custom?.fields?.shippingMethod === 'pickup')
}

export const getCartProductQtyByVariantSku = ({
  variantSku,
  cart,
  isBOPISProduct,
  userSelectedStoreNumber = '',
}: {
  variantSku: string
  cart?: Partial<Cart>
  isBOPISProduct: boolean
  userSelectedStoreNumber?: string
}) => {
  return (
    cart?.lineItems
      ?.filter((lineItem) => {
        const isSameSku = lineItem.variant?.sku === variantSku
        const isCorrectShippingMethod =
          lineItem.custom?.fields?.shippingMethod ===
          (isBOPISProduct ? SHIPPING_METHODS.PICKUP : SHIPPING_METHODS.SHIPPING)

        if (isBOPISProduct) {
          const isCorrectStore =
            userSelectedStoreNumber && lineItem.custom?.fields?.pickUpStoreId === userSelectedStoreNumber
          return isSameSku && isCorrectShippingMethod && isCorrectStore
        }

        return isSameSku && isCorrectShippingMethod
      })
      .reduce((totalCount, lineItem) => totalCount + lineItem.count, 0) || 0
  )
}

export const getCartProductByVariantSku = ({
  variantSku,
  cart,
  isBOPISProduct = false,
  userSelectedStoreNumber = '',
}: {
  variantSku: string
  cart?: Partial<Cart>
  isBOPISProduct?: boolean
  userSelectedStoreNumber: string
}) => {
  return cart?.lineItems?.filter(
    (lineItem) =>
      lineItem.variant?.sku === variantSku &&
      (userSelectedStoreNumber !== '' && lineItem.custom?.fields?.shippingMethod === SHIPPING_METHODS.PICKUP
        ? lineItem.custom?.fields?.pickUpStoreId === userSelectedStoreNumber
        : true) && // Ensures the condition is ignored if userSelectedStoreNumber is empty
      lineItem.custom?.fields?.shippingMethod ===
        (isBOPISProduct ? SHIPPING_METHODS.PICKUP : SHIPPING_METHODS.SHIPPING),
  )
}

export const getLineItemAmounts = (lineItem: LineItem) => {
  const { price, basePrice, priceMode, count } = lineItem
  const customizationCharge = lineItem?.custom?.fields?.customizationCharge?.centAmount
    ? lineItem?.custom?.fields?.customizationCharge?.centAmount
    : 0
  const isDiscounted = price?.centAmount - customizationCharge < basePrice?.centAmount

  return {
    isDiscounted: isDiscounted,
    price: price?.centAmount - customizationCharge,
    basePrice: basePrice?.centAmount,
  }
}

/* Address Utils */
export const getColoradoShippingFee = (cart: Cart) => {
  const coloradoRetailDeliveryFee = cart?.custom?.fields?.coloradoRetailDeliveryFee
  if (coloradoRetailDeliveryFee) {
    const amt = Math.round(+coloradoRetailDeliveryFee * 100)
    return amt > 0 ? amt : undefined
  }

  return undefined
}

export const getCartShippingInfo = (cart: Cart) => {
  const discountedShippingPrice = cart?.shippingInfo?.discountedPrice?.centAmount
  const shippingPrice = cart?.shippingInfo?.price?.centAmount || 0
  const isShippingMethodSelected = cart?.shippingInfo?.shippingMethodId

  return { shippingPrice, discountedShippingPrice, isShippingMethodSelected }
}

export function formatAddress(address: Address, isPickup = false) {
  return {
    name: isPickup
      ? `${address?.city} (Store ${address?.key})`
      : `${address?.firstName ?? ''} ${address?.lastName ?? ''}`,
    addressLine1: `${address?.streetNumber ?? ''} ${address?.streetName ?? ''}`,
    addressLine2: `${address?.city ?? ''}, ${address?.state ?? ''}, ${address?.postalCode ?? ''}`,
    phone: getPhoneformat(address?.phone),
    apartment: address?.apartment,
  }
}

/* Handling Fees Utils */

export const getHandlingFeesFromCart = (cart: Cart) => {
  if (
    !cart ||
    !cart.billingAddress ||
    !cart.shippingAddress ||
    Object.keys(cart?.billingAddress).length === 0 ||
    Object.keys(cart?.shippingAddress).length === 0
  ) {
    return undefined
    /*
      Show "Calculated at Checkout" till shipping/billing address is not available
      Returning "undefined" will ensure that instead of $0, "Calculated at Checkout" will be shown
    */
  }

  const hasHandlingFees = cart?.customLineItems?.some((item) => item.slug === 'handling-fees')
  if (hasHandlingFees) {
    return HANDLING_FEES_TIERS.DEFAULT.value
  } else {
    return HANDLING_FEES_TIERS.HC1.value
  }
}

const checkIsBillingOrShippingCalifornian = (cart: Cart) => {
  if (!cart || Object.keys(cart?.billingAddress).length === 0 || Object.keys(cart?.shippingAddress).length === 0)
    return false

  return cart?.billingAddress?.state === CALIFORNIA_STATE_CODE || cart?.shippingAddress?.state === CALIFORNIA_STATE_CODE
}

const checkSingleCartLineItemIsHC1Tier = (cart: Cart) => {
  if (!cart || !cart?.lineItems || !cart?.lineItems?.length || cart?.lineItems?.length !== 1) {
    return false

    /*
    Check if cart has only 1 lineItem AND
    Check if that single lineItem is HC1 tier

    A mixed cart with HC1 tier item and non-HC1 tier item will not be considered as HC1 tier cart

    BOPIS =  Buy Online, Pick Up In Store
  */
  }

  const lineItem = cart?.lineItems?.[0]
  const productSlug = lineItem?.slug?.includes('donation') || lineItem?.slug?.includes('gift') || false
  const isBOPIS = lineItem?.custom?.fields?.shippingMethod === SHIPPING_METHODS.PICKUP

  return productSlug || isBOPIS
}

const checkIfShipToStore = (cart: Cart) => {
  return cart?.shippingAddress?.additionalAddressInfo === 'shipToStore'
}

/* Cart Promotion Utils */

export enum DiscountCodeState {
  MatchesCart = 'MatchesCart',
  DoesNotMatchCart = 'DoesNotMatchCart',
}

export enum CouponStatus {
  INVALID = 'Invalid Coupon',
  VALID_ACTIVE = 'Coupon can be used',
  VALID_NON_ACTIVE = 'Coupon is valid but has not yet started or is expired',
}

export enum DiscountCodeErrors {
  DoesNotExist = 'DoesNotExist',
  TimeRangeNonApplicable = 'TimeRangeNonApplicable',
  DiscountCodeNonApplicable = 'DiscountCodeNonApplicable',
}

export enum PromoLoayltyStatus {
  LOYALTY_PROMO_MATCHES_CUSTOMER = 'LOYALTY_PROMO_MATCHES_CUSTOMER',
  LOYALTY_PROMO_DOES_NOT_MATCH_CUSTOMER = 'LOYALTY_PROMO_DOES_NOT_MATCH_CUSTOMER',
  NON_LOYALTY_PROMO = 'NON_LOYALTY_PROMO',
}

export enum LoyaltyTierKeywords {
  Classic = 'CLASSIC',
  Premiere = 'PREMIERE',
  Elite = 'ELITE',
}

export const TierMapping = {
  [LoyaltyTierKeywords.Classic]: ['classic', 'bronze', 'silver'],
  [LoyaltyTierKeywords.Premiere]: ['premiere', 'gold'],
  [LoyaltyTierKeywords.Elite]: ['elite', 'platinum'],
}

export const getPromoCodeLoyaltyTierFromCart = (cart: Cart) => {
  if (!cart) return undefined

  /*
    Only one discount(promo) code can be applied with a cart
    Hence we are taking the first discount code from the array
  */
  const discountCodes = cart?.discountCodes?.[0]

  if (!discountCodes || discountCodes?.state === DiscountCodeState.DoesNotMatchCart) return undefined

  return checkIsLoyaltyPromo(cart?.discountCodes?.[0]?.name) ? cart?.discountCodes?.[0]?.description : undefined
}

export const isCode13DigitCouponCode = (code: string) => {
  return code.length === 13 && !isNaN(Number(code))
}

export const getLoyaltyTierFromString = (input: string): LoyaltyTierKeywords | null => {
  if (!input) return undefined

  const normalizedInput = input.toLowerCase()

  for (const [tier, keywords] of Object.entries(TierMapping)) {
    if (keywords.some((keyword) => normalizedInput.includes(keyword))) {
      return tier as LoyaltyTierKeywords
    }
  }

  return undefined
}

export const checkIsLoyaltyPromo = (name: string): LoyaltyTierKeywords | undefined => {
  return getLoyaltyTierFromString(name)
}

export const getCustomerLoyaltyTier = (customerGroupKey: string): LoyaltyTierKeywords | null => {
  return getLoyaltyTierFromString(customerGroupKey)
}

export const checkIsPromoLoyaltySameAsCustomerLoyalty = (
  isPromoLoyalty: string | null | undefined,
  customerLoyaltyTier: string | null | undefined,
) => {
  if (!isPromoLoyalty || !customerLoyaltyTier) {
    return 'NON_LOYALTY_PROMO'
  } else if (isPromoLoyalty === customerLoyaltyTier) {
    return 'LOYALTY_PROMO_MATCHES_CUSTOMER'
  } else if (isPromoLoyalty !== customerLoyaltyTier) {
    return 'LOYALTY_PROMO_DOES_NOT_MATCH_CUSTOMER'
  }
}

/* General Util */

export const raiseToast = (title: string, description: string, statusMessage: 'success' | 'error' | 'info', toast) => {
  if (!toast) return null

  return toast({
    title: title,
    description: description,
    status: statusMessage,
    duration: 4000,
    isClosable: true,
    position: 'top',
  })
}

export const getPropFromApplepayResponse = (applePayAuthResponse, prop) => {
  const parsedResponse = JSON.parse(applePayAuthResponse)
  return parsedResponse?.[prop]
}

export const isCartTotalEligibleForAfterpay = (total: number) => {
  return total >= AFTERPAY_MIN_CARTVALUE_IN_CENT && total <= AFTERPAY_MAX_CARTVALUE_IN_CENT
}
