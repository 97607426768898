import React, { useEffect, useState } from 'react'
import Script from 'next/script'

const Tealium = () => {
  const [shouldLoadScript, setShouldLoadScript] = useState(false)

  useEffect(() => {
    const checkOneTrust = () => {
      if (typeof window.OneTrust !== 'undefined') {
        setShouldLoadScript(true)
      }
    }

    checkOneTrust()

    const observer = new MutationObserver(() => {
      checkOneTrust()
    })
    observer.observe(document.body, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [])

  return (
    <>
      {shouldLoadScript && (
        <Script id="tealium">
          {`
      (function(a,b,c,d){
        a = '//tags.tiqcdn.com/utag/dxl/spa/' + ((window.location.hostname.indexOf('www') === 0) ? 'prod' : 'dev') + '/utag.js';
      b=document;
      c='script';
      d=b.createElement(c);
      d.src=a;
      d.type='text/java'+c;
      d.async=true;
      a=b.getElementsByTagName(c)[0];
      a.parentNode.insertBefore(d,a);
        })()
      `}
        </Script>
      )}
    </>
  )
}

export default Tealium
