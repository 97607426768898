import { getRouteData, getPreview, getCategories, getStructure } from './lib/server/get-route-data'

export function createClient() {
  return {
    getRouteData: getRouteData(),
    getPreview: getPreview(),
    getCategories: getCategories(),
    getStructure: getStructure(),
  }
}

export const revalidateOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: true,
}
