import { Cart } from '@Types/cart/Cart'
import { OptimizedProduct } from '@Types/product/OptimizedPDPDataSource'
import { Product } from '@Types/product/Product'
import { Variant } from '@Types/product/Variant'
import { ADDRESS_TYPE, BOPIS } from 'composable/components/checkout/constants'
import { fetchAttributeData } from 'composable/components/horizontal-product-card/utils'
import { StarterKitAlgoliaProduct } from 'composable/components/plp/types'
import {
  PAYMENT_METHOD_APPLEPAY,
  PAYMENT_METHOD_CARDPAYMENT,
  PAYMENT_METHOD_CREDITCART,
  PAYMENT_METHOD_GCRC,
  PAYMENT_METHOD_PAYPAL,
} from 'composable/helpers/constants/checkout'
import { getCartSummaryAmounts, getHandlingFeesFromCart } from 'composable/helpers/utils/cart-utils'
import { HomePageInterface, StaticPageInterface } from 'composable/tealium-interfaces/TealiumPageInterface'
import { Account } from 'shared/types/account'
import { ShippingMethod } from 'shared/types/cart'
import { UtagManager } from 'frontastic/tastics/tealium/utag.manager'
import { TEALIUM_SITE_ID } from '../composable/components/general/constants'
import { AtgLegacyCart } from '../frontastic/contexts/atgCartContext/interfaces/AtgLegacyCartData'
declare var window

var clpPromoClickData = []

if (typeof window !== 'undefined') {
  var utagViewQueue = new UtagManager('utagViewQueue', 100, window, function () {
    this.running = true
    if (!this.isEmpty()) {
      if (this.window.utag) {
        let utag_data = this.front()
        this.dequeue()
        this.window.utag.track(utag_data[0], utag_data[1])
      }
      setTimeout(() => {
        this.processQueue()
      }, this.speed)
    } else {
      this.running = false
    }
  })
}
const getCartSubTotal = (cartData: any) => {
  let { subtotal: cartSubTotal }: any = getCartSummaryAmounts(cartData)
  cartSubTotal = (Number(cartSubTotal || 0) / 100).toFixed(2)
  return cartSubTotal
}
const getMediaQueries = () => {
  let viewport: string

  const width = window.innerWidth
  const height = window.innerHeight
  const userAgent = navigator.userAgent
  const userAgentShowsMobile =
    userAgent.indexOf('Mobile') !== -1 || userAgent.indexOf('Mobi') !== -1 || userAgent.indexOf('Mini') !== -1

  if (width < 768 && userAgentShowsMobile) {
    viewport = 'mobile'
  } else if (width >= 768 && width <= 1024 && userAgentShowsMobile) {
    viewport = 'tablet'
  } else if (width > 1024) {
    viewport = 'desktop'
  }
  return viewport
}

const utagView = (utag_data, country_code, currency_code) => {
  if (utag_data && utag_data.length) {
    const currLocation = window.location.href

    let viewport = getMediaQueries()
    let countryData = {
      country_code: country_code,
      currency_code: currency_code === 'USD' ? '$' : currency_code,
    }
    utag_data[1].country_code = countryData['country_code']
    if (countryData['currency_code'] === '$') {
      utag_data[1].currency_code = 'USD'
    } else {
      utag_data[1].currency_code = countryData['currency_code']
    }
    utag_data[1].ismobile = viewport
    let date = new Date()
    utag_data[1].tealium_timestamp_epoch = Math.floor(date.getTime() / 1000)
    utag_data[1].tealium_timestamp_local = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1)
    utag_data[1].tealium_timestamp_utc = date.toISOString()
    utagViewQueue?.enqueue(utag_data)
  }
}

const utagLink = (utag_data, country_code?: string, currency_code?: string) => {
  utagView(utag_data, country_code, currency_code)
}

const promoClickUtagLinkEvent = (data: any) => {
  const promoDetails = {}
  const utagData = {}
  utagData['tealium_event'] = 'promo_click'
  promoDetails['promotion_name'] = data
  if (data && data !== '') {
    clpPromoClickData.push(promoDetails)
    window.localStorage.setItem('clpPromoClickData', JSON.stringify(clpPromoClickData))
    utagData['promotion_name'] = getClickedPromoNames()
    utagLink(['link', utagData])
  }
}

const getClickedPromoNames = () => {
  let promos = []
  const clpPromoClickData = JSON.parse(window.localStorage.getItem('clpPromoClickData'))
  clpPromoClickData?.forEach((item) => {
    if (item.promotion_name) {
      promos.push(item.promotion_name)
    }
  })
  return promos
}
const staticPathRegex = /^\/static\/[^/]+$/

const getTealiumPageName = (path: string) => {
  if (staticPathRegex.test(path)) {
    return path
  }
  return 'Home Page'
}

const getPLPCategory = (path: string) => {
  const params = path?.split('/')
  const catWithQuery = params[params.length - 1]
  const category = catWithQuery?.split('?')[0] ?? ''
  return category
}

const getSelectedPaymentType = (selectedPayment: string, cart: Cart, totalGCRCValue: number) => {
  let paymentMethods = []

  let latestPaymentMethod = cart?.payments?.[0]
  let latestPaymentProvider = latestPaymentMethod?.paymentProvider || ''
  latestPaymentProvider = latestPaymentProvider.toLowerCase()
  selectedPayment = selectedPayment || latestPaymentMethod?.paymentProvider || ''

  if (selectedPayment === PAYMENT_METHOD_GCRC) {
    paymentMethods.push('giftCard')
  } else if (selectedPayment === PAYMENT_METHOD_CREDITCART || selectedPayment === PAYMENT_METHOD_CARDPAYMENT) {
    paymentMethods.push('creditCard')
  } else if (selectedPayment === PAYMENT_METHOD_PAYPAL || latestPaymentProvider == PAYMENT_METHOD_PAYPAL) {
    paymentMethods.push('PayPal')
  } else if (selectedPayment === PAYMENT_METHOD_APPLEPAY) {
    paymentMethods.push('applePay')
  }

  return paymentMethods[0]
}

const getTealiumPageCategory = (path: string) => {
  if (staticPathRegex.test(path)) {
    return 'editorial'
  } else if (path.indexOf('/c/') > -1) {
    return getPLPCategory(path)
  }
  return 'homepage'
}

const getTealiumPageType = (path: string) => {
  if (staticPathRegex.test(path)) {
    return 'static'
  }
  return 'home'
}

const getTealiumSiteSection = (path: string) => {
  if (staticPathRegex.test(path)) {
    return 'static/editorial'
  }
  return 'homepage'
}

const constructUtagData = (path, userDetails: Account, userSessionData: any, otherParams?: any) => {
  const pageWiseUtagData = buildPageWiseUtagData(path, userDetails, userSessionData)
  const utag_data = ['view', { ...pageWiseUtagData, ...otherParams }]
  return utag_data
}

const buildBaseTealiumUtagData = (path, userDetails: Account, userSessionData) => {
  const baseTealiumUtagData = {
    site_id: TEALIUM_SITE_ID,
    available_points: userSessionData?.firstName ? userSessionData?.userAvailablePoints : '',
    current_tier: userSessionData?.firstName ? userSessionData?.userCurrentTier : '',
    user_mystore: userSessionData?.store ? userSessionData?.store.displayStoreNumber : '',
    user_sizeprofile: 'off',
    customer_email: userDetails?.email || undefined,
    customer_postal_code: userDetails?.addresses?.[0]?.postalCode || undefined,
    customer_id: userDetails?.accountId || undefined,
    crm_customer_number: userDetails?.externalId || undefined,
    isThirdParty: 'true',
    page_category: getTealiumPageCategory(path),
    cm_category_id: getTealiumPageCategory(path),
    page_type: getTealiumPageType(path),
    page_name: getTealiumPageName(path),
    site_section: getTealiumSiteSection(path),
    cm_page_id: path,
    page_url: path,
  }
  return baseTealiumUtagData
}
const buildPageWiseUtagData = (path, userDetails, userSessionData) => {
  const baseTealiumUtagData = buildBaseTealiumUtagData(path, userDetails, userSessionData)
  if (staticPathRegex.test(path)) {
    const staticPageUtagData: StaticPageInterface = {
      hashed_email: userDetails?.profile?.['email'] || undefined,
      customer_first_name: userSessionData?.firstName || undefined,
      customer_last_name: userSessionData?.lastName || undefined,
      ...baseTealiumUtagData,
    }
    return staticPageUtagData
  }

  const homePageUtagData: HomePageInterface = {
    in_iframe: 0,
    is_confirmation: 0,
    user_loginstatus: userDetails?.firstName ? true : false,
    site_breadcrumb: 'Home',
    ...baseTealiumUtagData,
  }
  return homePageUtagData
}

const getAddedProductSize = (key) => {
  const arr = key.split(' ')
  if (arr.length > 2) {
    return [arr.slice(2).join('-')]
  } else {
    return []
  }
}

// Moving this function to this file as it exists. It could be improved.
const utagAddToCart = ({
  product,
  currentVariant,
  currentChildProduct,
  variant,
  breadcrumbs,
  qty,
  isLoggedIn,
  userDetails,
  cartData,
  algoliaQueryId,
}: {
  product?: Product
  currentVariant: Variant
  currentChildProduct?: OptimizedProduct
  variant: Variant
  qty: Number
  cartData?: AtgLegacyCart | Cart
  breadcrumbs?: any
  isLoggedIn: boolean
  userDetails: Account
  algoliaQueryId: string
}) => {
  // const isLoggedIn = !!userSessionData?.firstName

  let order_grand_total = []
  if (cartData && 'totals' in cartData) {
    order_grand_total = [cartData?.totals?.convAmount]
  } else if (cartData && 'sum' in cartData) {
    order_grand_total = [(cartData.sum.centAmount / 100).toFixed(cartData.sum.fractionDigits)]
  }
  const utagObj = [
    'link',
    {
      tealium_event: 'cart_add',
      order_subtotal: [getCartSubTotal(cartData)],
      product_id: [product.attributes?.itemNumber],
      product_name: [product?.name],
      product_brand: [product?.attributes?.brand],
      product_variant: [product.attributes?.ecommColor],
      product_list_price: currentVariant?.discountedPrice
        ? (currentVariant?.discountedPrice?.centAmount / 100).toFixed(2)
        : ((currentChildProduct || currentVariant)?.price?.centAmount / 100).toFixed(2),
      product_unit_price: [((currentChildProduct || currentVariant)?.price?.centAmount / 100).toFixed(2)],
      product_quantity: [qty],
      product_size: getAddedProductSize(currentVariant?.key),
      product_color: [product.attributes?.ecommColor],
      product_sku: [variant.sku],
      site_id: 'dxl',
      site_section: [breadcrumbs?.[0]?.label ?? undefined],
      site_sub_section: [breadcrumbs?.[1]?.label ?? undefined],
      site_sub_section_level3: [breadcrumbs?.[2] && breadcrumbs?.[2]?.link ? breadcrumbs?.[2]?.label : undefined],
      product_category: [breadcrumbs?.[0]?.label ?? undefined],
      product_category2: [breadcrumbs?.[1]?.label ?? undefined],
      product_category3: [breadcrumbs?.[2] && breadcrumbs?.[2]?.link ? breadcrumbs?.[2]?.label : undefined],
      event_type: 'product details',
      hashed_email: userDetails?.email || undefined,
      customer_email: userDetails?.email || undefined,
      customer_postal_code: userDetails?.addresses?.[0]?.postalCode || undefined,
      customer_id: userDetails?.accountId || undefined,
      crm_customer_number: userDetails?.externalId || undefined,
      user_loginstatus: isLoggedIn,
      product_algolia_query_id: [algoliaQueryId ?? ''],
      site_breadcrumb: breadcrumbs?.map((breadcrumb) => breadcrumb.label).join(' > '),
      order_grand_total,
      ...JSON.parse(localStorage.getItem('lastProductClicked')),
    },
  ]
  utagLink(utagObj)
}
const utagViewCart = ({
  isLoggedIn,
  userDetails,
  cartData,
  pageUrl,
  displayStoreNumber,
  algoliaProductAddedToCart,
  userSessionData,
  couponCode,
  available_points,
  currentTier,
  totalItems,
}: {
  cartData?: Cart
  isLoggedIn: boolean
  userDetails: Account
  pageUrl: string
  displayStoreNumber: string
  algoliaProductAddedToCart: any
  userSessionData: Account
  couponCode: string
  available_points: string
  currentTier: string
  totalItems: number
}) => {
  // const isLoggedIn = !!userSessionData?.firstName

  const {
    productBrands,
    productNames,
    product_impression_position,
    productBreadCrumbs,
    productDiscounts,
    productIds,
    productList,
    product_quantity,
    productSize,
    productVariant,
    productSkus,
    productListprice,
    productUnitprice,
    order_grand_total,
    currency_code,
    country_code,
    promotionClickDatas,
    promoCode,
    productAlgoliaQueryIds,
    taxes,
  } = tealiumCheckoutHelper(cartData, algoliaProductAddedToCart, isLoggedIn)

  const utagObj = {
    product_category: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[0]?.label ?? undefined),
    product_category2: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[1]?.label ?? undefined),
    product_category3: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[2]?.label ?? undefined),
    product_list: productList,
    product_impression_position: product_impression_position,
    cart_total_items: totalItems,
    cart_total_value: order_grand_total,
    site_breadcrumb: undefined,
    cm_category_id: 'Order',
    cm_page_id: 'Shopping Cart',
    available_points: available_points,
    current_tier: currentTier,
    page_category: 'cart',
    page_name: 'Shopping Cart',
    page_type: 'cart',
    page_url: pageUrl,
    promotion_id: promotionClickDatas.map((item) => item?.id || ''),
    promotion_name: promotionClickDatas.map((item) => item?.name),
    potential_order_id: '',
    product_color: productVariant,
    product_variant: productVariant,
    product_id: productIds,
    product_name: productNames,
    product_quantity: product_quantity,
    product_size: productSize,
    product_sku: productSkus,
    product_list_price: productListprice,
    product_unit_price: productUnitprice,
    product_discount_amount: productDiscounts,
    promo_code: promoCode || couponCode,
    site_section: 'cart',
    order_grand_total,
    order_subtotal: [getCartSubTotal(cartData)],
    order_tax_amount: taxes || 0,
    order_discount_amount: cartData?.totalDiscountOnTotalPrice,
    user_loginstatus: isLoggedIn,
    user_mystore: displayStoreNumber,
    user_sizeprofile: 'off',
    product_brand: productBrands,
    product_algolia_query_id: productAlgoliaQueryIds,
  }
  const utagData = constructUtagData(pageUrl, userDetails, userDetails, utagObj)
  utagView(utagData, country_code, currency_code)
}
const utagAddToWishlist = ({
  product,
  currentVariant,
  breadcrumbs,
  qty,
  userSessionData,
  cartData,
  algoliaQueryId,
}: {
  product?: Product
  currentVariant: Variant
  qty: Number
  cartData?: AtgLegacyCart | Cart
  breadcrumbs?: any
  userSessionData: any
  algoliaQueryId: string
}) => {
  const isLoggedIn = !!userSessionData?.firstName

  let order_grand_total = []
  if (cartData && 'totals' in cartData) {
    order_grand_total = [cartData?.totals?.convAmount]
  } else if (cartData && 'sum' in cartData) {
    order_grand_total = [(cartData.sum.centAmount / 100).toFixed(cartData.sum.fractionDigits)]
  }

  const utagObj = [
    'link',
    {
      tealium_event: 'wishlist_add',
      product_algolia_query_id: [algoliaQueryId ?? ''],
      product_id: [currentVariant.attributes?.itemNumber],
      product_name: [product.name],
      site_section: [breadcrumbs?.[0]?.label ?? undefined],
      site_sub_section: [breadcrumbs?.[1]?.label ?? undefined],
      site_sub_section_level3: [breadcrumbs?.[2] && breadcrumbs?.[2]?.link ? breadcrumbs?.[2]?.label : undefined],
      product_category: [breadcrumbs?.[0]?.label ?? undefined],
      product_category2: [breadcrumbs?.[1]?.label ?? undefined],
      product_category3: [breadcrumbs?.[2] && breadcrumbs?.[2]?.link ? breadcrumbs?.[2]?.label : undefined],
      site_sub_section_level4: [breadcrumbs?.[3] ? breadcrumbs?.[3].label : undefined],
      product_color: currentVariant.attributes?.ecommColor ? [currentVariant.attributes?.ecommColor] : [],
      product_list_price: [
        currentVariant?.discountedPrice
          ? (currentVariant?.discountedPrice?.centAmount / 100).toFixed(2)
          : (currentVariant?.price?.centAmount / 100).toFixed(2),
      ],
      product_unit_price: [(currentVariant?.price?.centAmount / 100).toFixed(2)],
      product_variant: [currentVariant.attributes?.ecommColor],
      product_size: getAddedProductSize(currentVariant?.key),
      product_sku: [currentVariant?.sku],
      product_quantity: [qty],
      order_grand_total,
      order_subtotal: [getCartSubTotal(cartData)],
    },
  ]
  utagLink(utagObj)
}
export const utagProductClick = (
  record: Partial<StarterKitAlgoliaProduct>,
  color_name: string,
  prodctId: string,
  userSessionData: any,
  algoliaQueryId: string,
) => {
  let price: string | number = record.price?.centAmount?.[0] || 0
  price /= 100
  price = price.toFixed(2)
  const utagData = [
    'link',
    {
      tealium_event: 'product_click',
      product_id: [record?.key],
      product_name: record?.name ? [record?.name] : [],
      product_variant: [color_name ?? record?.colorFamily ?? ''],
      product_category: record.categories || [],
      product_price: [price || ''],
      product_impression_position: record?.__position ? [(Number(record?.__position) || 0) + 1] : [],
      product_list: ['search - search'],
      product_algolia_query_id: [algoliaQueryId ?? ''],
      representative_sku_id: record?.objectID ? [record?.objectID] : [''],
    },
  ]
  utagView(utagData, userSessionData?.dxlCountry, userSessionData?.dxlCurrency)
}

const utagRemoveToCart = ({
  product,
  currentVariant,
  variant,
  breadcrumbs,
  qty,
  isLoggedIn,
  userDetails,
  cartData,
  algoliaQueryId,
}: {
  product?: Product
  currentVariant: Variant
  variant: Variant
  qty: Number
  cartData?: AtgLegacyCart | Cart
  breadcrumbs?: any
  isLoggedIn: boolean
  userDetails: Account
  algoliaQueryId: string
}) => {
  // const isLoggedIn = !!userSessionData?.firstName
  let order_grand_total = []
  if (cartData && 'totals' in cartData) {
    order_grand_total = [cartData?.totals?.convAmount]
  } else if (cartData && 'sum' in cartData) {
    order_grand_total = [(cartData.sum.centAmount / 100).toFixed(cartData.sum.fractionDigits)]
  }
  const utagObj = [
    'link',
    {
      tealium_event: 'cart_remove',
      product_id: [variant.attributes?.itemNumber],
      product_name: [product?.name],
      product_brand: [product?.attributes?.brand],
      product_variant: [variant.attributes?.ecommColor],
      product_list_price: (currentVariant?.price?.centAmount / 100).toFixed(2),
      product_unit_price: [(currentVariant?.price?.centAmount / 100).toFixed(2)],
      product_quantity: [qty],
      product_size: getAddedProductSize(currentVariant?.key),
      product_color: [variant.attributes?.ecommColor],
      product_sku: [variant.sku],
      site_id: 'dxl',
      site_section: [breadcrumbs?.[0]?.label ?? undefined],
      site_sub_section: [breadcrumbs?.[1]?.label ?? undefined],
      site_sub_section_level3: [breadcrumbs?.[2] && breadcrumbs?.[2]?.link ? breadcrumbs?.[2]?.label : undefined],
      product_category: [breadcrumbs?.[0]?.label ?? undefined],
      product_category2: [breadcrumbs?.[1]?.label ?? undefined],
      product_category3: [breadcrumbs?.[2] && breadcrumbs?.[2]?.link ? breadcrumbs?.[2]?.label : undefined],
      hashed_email: userDetails?.email || undefined,
      customer_email: userDetails?.email || undefined,
      customer_postal_code: userDetails?.addresses?.[0]?.postalCode || undefined,
      customer_id: userDetails?.accountId || undefined,
      crm_customer_number: userDetails?.externalId || undefined,
      user_loginstatus: isLoggedIn,
      product_algolia_query_id: [algoliaQueryId ?? ''],
      site_breadcrumb: breadcrumbs?.map((breadcrumb) => breadcrumb.label).join(' > '),
      order_grand_total,
      order_subtotal: [getCartSubTotal(cartData)],
    },
  ]
  utagLink(utagObj)
}

const utagApplyCouponCode = (couponCode: string) => {
  let utagObj = [
    'link',
    {
      tealium_event: 'coupon_apply',
      promo_code: couponCode,
    },
  ]
  utagLink(utagObj)
}
interface Attribute {
  name: string
  value: string
}
const getBreadCrumbsFromMasterVariantAttribues = (attributes: Attribute[]) => {
  let primeCategory = attributes.find((attribute) => {
    return attribute?.name === 'primaryCategory'
  })
  if (!primeCategory) return []
  let breadcrumbs = primeCategory?.value?.trim()?.split('=>')
  return breadcrumbs.filter((value) => !!value).map((item) => ({ label: item, link: item }))
}

const tealiumShippingMethodHelper = (shippingMethod: ShippingMethod, cart: Cart) => {
  let shippingMethods = []
  let shipTypes = []
  cart?.lineItems?.forEach((item) => {
    if (item?.custom?.fields?.shippingMethod === BOPIS) {
      shippingMethods.push('Quick Store Pick Up')
      shipTypes.push('Store Pickup')
    } else if (cart?.shippingAddress?.additionalAddressInfo == ADDRESS_TYPE.SHIP_TO_STORE) {
      {
        shippingMethods.push(shippingMethod?.name)
        shipTypes.push('Store Ship')
      }
    } else {
      shippingMethods.push(shippingMethod?.name)
      shipTypes.push('ship')
    }
  })
  return {
    shippingMethods,
    shipTypes,
  }
}
export const tealiumCheckoutHelper = (cartData: Cart, algoliaProductAddedToCart: any, isLoggedIn: boolean) => {
  let promotionClickDatas = []
  let str = localStorage.getItem('clpPromoClickData')
  const promoCode = cartData?.discountCodes?.[0]?.code || ''
  try {
    let datas = JSON.parse(str)
    datas?.forEach((item) => {
      promotionClickDatas.push({
        name: item?.promotion_name || '',
        id: item?.promotion_id,
      })
    })
  } catch (e) {
    console.log('error while parsing')
  }
  let { shippingPrice, subtotal: cartSubTotal, taxes }: any = getCartSummaryAmounts(cartData)
  shippingPrice = (Number(shippingPrice || 0) / 100).toFixed(2)
  cartSubTotal = (Number(cartSubTotal || 0) / 100).toFixed(2)
  taxes = (Number(taxes || 0) / 100).toFixed(2)
  let handlingFees = (Number(getHandlingFeesFromCart(cartData) || 0) / 100).toFixed(2)
  let totalDiscountOnTotalPrice: string | number = cartData?.totalDiscountOnTotalPrice || 0
  totalDiscountOnTotalPrice = Number(totalDiscountOnTotalPrice).toFixed(2)

  let order_grand_total = []
  let total = 0
  let subTotal = 0
  if (cartData && 'totalTaxOncart' in cartData) {
    total = cartData?.totalTaxOncart?.totalGross?.centAmount || 0
  }
  if (cartData && 'sum' in cartData) {
    subTotal = cartData?.sum?.centAmount || cartData.sum.centAmount
  }
  total = total || subTotal || 0
  order_grand_total = [(total / 100).toFixed(2)]
  const productBrands = []
  const productNames = []
  const product_impression_position = []
  const productBreadCrumbs = []
  const productDiscounts = []
  const productIds = []
  const productList = []
  const product_quantity = []
  const productSize = []
  const productVariant = []
  const productSkus = []
  const productListprice = []
  const productUnitprice = []
  let productAlgoliaQueryIds = []
  let attentiveIds = []
  cartData?.lineItems?.forEach((item) => {
    const masterProductData = item?.masterData?.current?.masterVariant?.attributes

    const product =
      algoliaProductAddedToCart.filter((product) => item?.variant?.sku && product?.skuId == item?.variant?.sku).pop() ||
      {}
    let image = {
      src: item?.variant?.images?.[0],
      alt: item?.name ?? '',
    }
    const { brand } = fetchAttributeData({
      image,
      masterProductData,
      variant: item?.variant,
      removedSegment3LabelWording: false,
    })
    productBrands.push(brand || undefined)
    productNames.push(item?.name)
    if (product?.breadcrumbs) {
      productBreadCrumbs.push(product?.breadcrumbs || [])
    } else {
      productBreadCrumbs.push(
        getBreadCrumbsFromMasterVariantAttribues(item?.masterData?.current?.masterVariant?.attributes || []),
      )
    }
    product_impression_position.push(product?.product_impression_position?.[0])
    let price = item?.price?.centAmount || 0
    let discountedPrice = item?.discountedPrice?.centAmount || price || 0
    let discountAmount: string | number = item?.isDiscounted ? price - discountedPrice : 0
    discountAmount = (discountAmount / 100).toFixed(2)
    productDiscounts.push(discountAmount)
    let itemNumber = item?.variant?.attributes?.itemNumber || ''
    let attentiveId = itemNumber + '_' + item?.variant?.attributes?.ecommColor?.replaceAll(' ', '_')?.toLowerCase()
    productList.push(product?.product_list || [])
    productIds.push(itemNumber)
    product_quantity.push(item?.count)
    productSize.push(getAddedProductSize(item?.variant?.key || ''))
    productVariant.push(item?.variant?.attributes?.ecommColor)
    productSkus.push(item?.variant?.sku)
    productListprice.push((discountedPrice / 100).toFixed(2))
    productUnitprice.push((price / 100).toFixed(2))
    attentiveIds.push(attentiveId)
    if (product?.algolia_query_id) productAlgoliaQueryIds.push(product?.algolia_query_id)
  })
  const totalItems = cartData?.lineItems?.reduce((acc, curr) => acc + (curr?.count || 0), 0) || 0

  return {
    productBrands,
    productNames,
    product_impression_position,
    productBreadCrumbs,
    productDiscounts,
    productIds,
    productList: productList.flat(),
    product_quantity,
    productSize: productSize.flat(),
    productVariant,
    productSkus,
    productListprice,
    productUnitprice,
    order_grand_total,
    order_subtotal: [getCartSubTotal(cartData)],
    handlingFees: handlingFees ?? 0,
    checkoutUserType: isLoggedIn ? 'Signed In' : 'guest',
    currency_code: cartData?.locale?.currency,
    country_code: cartData?.locale?.country,
    promotionClickDatas,
    promoCode,
    productAlgoliaQueryIds,
    totalDiscountOnTotalPrice,
    shippingPrice,
    taxes,
    subtotal: cartSubTotal,
    totalItems,
    attentiveIds,
  }
}
const utagCheckoutStep1 = ({
  isLoggedIn,
  userDetails,
  cartData,
  pageUrl,
  displayStoreNumber,
  algoliaProductAddedToCart,
  userSessionData,
  couponCode,
  available_points,
  currentTier,
}: {
  cartData?: Cart
  isLoggedIn: boolean
  userDetails: Account
  pageUrl: string
  displayStoreNumber: string
  algoliaProductAddedToCart: any
  userSessionData: Account
  couponCode: string
  available_points: string
  currentTier: string
}) => {
  // const isLoggedIn = !!userSessionData?.firstName

  const {
    productBrands,
    productNames,
    product_impression_position,
    productBreadCrumbs,
    productDiscounts,
    productIds,
    productList,
    product_quantity,
    productSize,
    productVariant,
    productSkus,
    productListprice,
    productUnitprice,
    order_grand_total,
    handlingFees,
    checkoutUserType,
    currency_code,
    country_code,
    promotionClickDatas,
    promoCode,
  } = tealiumCheckoutHelper(cartData, algoliaProductAddedToCart, isLoggedIn)

  const utagObj = [
    'view',
    {
      tealium_event: 'begin_checkout',
      available_points: available_points,
      checkout_step: 1,
      checkout_usertype: checkoutUserType,
      cm_category_id: 'checkout',
      cm_page_id: 'shipping',
      current_tier: currentTier,
      handling_fee: handlingFees,
      order_grand_total,
      order_subtotal: [getCartSubTotal(cartData)],
      page_category: 'checkout',
      page_name: 'Checkout > Shipping',
      page_type: 'checkout',
      page_url: pageUrl,
      product_brand: productBrands,
      product_category: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[0]?.label ?? undefined),
      product_category2: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[1]?.label ?? undefined),
      product_category3: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[2]?.label ?? undefined),
      product_color: productVariant,
      product_discount_amount: productDiscounts,
      product_id: productIds,
      product_impression_position: product_impression_position,
      product_list: productList,
      product_list_price: productListprice,
      product_name: productNames,
      product_quantity: product_quantity,
      product_size: productSize,
      product_sku: productSkus,
      product_unit_price: productUnitprice,
      product_variant: productVariant,
      promo_code: promoCode || couponCode,
      promotion_id: promotionClickDatas.map((item) => item?.id || ''),
      promotion_name: promotionClickDatas.map((item) => item?.name),
      user_loginstatus: isLoggedIn,
      user_mystore: displayStoreNumber,
      site_id: 'dxl',
      hashed_email: userDetails?.email || undefined,
      customer_email: userDetails?.email || undefined,
      customer_postal_code: userDetails?.addresses?.[0]?.postalCode || undefined,
      customer_id: userDetails?.accountId || undefined,
      crm_customer_number: userDetails?.externalId || undefined,
    },
  ]
  utagView(utagObj, country_code, currency_code)
}

const utagViewCheckoutStep2 = ({
  isLoggedIn,
  userDetails,
  cartData,
  pageUrl,
  displayStoreNumber,
  algoliaProductAddedToCart,
  userSessionData,
  couponCode,
  available_points,
  currentTier,
  totalItems,
  selectedShippingMethod,
}: {
  cartData?: Cart
  isLoggedIn: boolean
  userDetails: Account
  pageUrl: string
  displayStoreNumber: string
  algoliaProductAddedToCart: any
  userSessionData: Account
  couponCode: string
  available_points: string
  currentTier: string
  totalItems: number
  selectedShippingMethod: ShippingMethod
}) => {
  // const isLoggedIn = !!userSessionData?.firstName
  const { shippingMethods, shipTypes } = tealiumShippingMethodHelper(selectedShippingMethod, cartData)
  const {
    productBrands,
    productNames,
    product_impression_position,
    productBreadCrumbs,
    productDiscounts,
    productIds,
    productList,
    product_quantity,
    productSize,
    productVariant,
    productSkus,
    productListprice,
    productUnitprice,
    order_grand_total,
    currency_code,
    country_code,
    promotionClickDatas,
    promoCode,
    productAlgoliaQueryIds,
    handlingFees,
    taxes,
  } = tealiumCheckoutHelper(cartData, algoliaProductAddedToCart, isLoggedIn)
  const utagObj = {
    product_category: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[0]?.label ?? undefined),
    product_category2: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[1]?.label ?? undefined),
    product_category3: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[2]?.label ?? undefined),
    product_list: productList,
    product_impression_position: product_impression_position,
    handling_fee: handlingFees,
    cart_total_items: totalItems,
    cart_total_value: order_grand_total,
    site_breadcrumb: undefined,
    cm_category_id: 'checkout',
    cm_page_id: 'billing',
    page_name: 'Checkout > billing',
    page_category: 'checkout',
    page_type: 'checkout',
    available_points: available_points,
    current_tier: currentTier,
    page_url: pageUrl,
    checkout_shipmethod: shippingMethods,
    checkout_shiptype: shipTypes,
    product_shippingoption: shippingMethods,
    promotion_id: promotionClickDatas.map((item) => item?.id || ''),
    promotion_name: promotionClickDatas.map((item) => item?.name),
    potential_order_id: '',
    product_color: productVariant,
    product_variant: productVariant,
    product_id: productIds,
    product_name: productNames,
    product_quantity: product_quantity,
    product_size: productSize,
    product_sku: productSkus,
    product_list_price: productListprice,
    product_unit_price: productUnitprice,
    product_discount_amount: productDiscounts,
    promo_code: promoCode || couponCode,
    site_section: undefined,
    order_grand_total,
    order_subtotal: [getCartSubTotal(cartData)],
    order_tax_amount: taxes,
    order_discount_amount: cartData?.totalDiscountOnTotalPrice,
    user_loginstatus: isLoggedIn,
    user_mystore: displayStoreNumber,
    user_sizeprofile: 'off',
    product_brand: productBrands,
    product_algolia_query_id: productAlgoliaQueryIds,
    customer_email: userDetails?.email || cartData?.email,
  }
  const utagData = constructUtagData(pageUrl, userDetails, userDetails, utagObj)
  utagView(utagData, country_code, currency_code)
}

const utagCheckoutAddShippingMethod = ({
  isLoggedIn,
  userDetails,
  cartData,
  pageUrl,
  displayStoreNumber,
  algoliaProductAddedToCart,
  userSessionData,
  couponCode,
  selectedShippingMethod,
  available_points,
  currentTier,
}: {
  cartData?: Cart
  isLoggedIn: boolean
  userDetails: Account
  pageUrl: string
  displayStoreNumber: string
  algoliaProductAddedToCart: any
  userSessionData: Account
  couponCode: string
  selectedShippingMethod: ShippingMethod
  available_points: string
  currentTier: string
}) => {
  // const isLoggedIn = !!userSessionData?.firstName
  const { shippingMethods, shipTypes } = tealiumShippingMethodHelper(selectedShippingMethod, cartData)
  const {
    productBrands,
    productNames,
    product_impression_position,
    productBreadCrumbs,
    productDiscounts,
    productIds,
    productList,
    product_quantity,
    productSize,
    productVariant,
    productSkus,
    productListprice,
    productUnitprice,
    order_grand_total,
    handlingFees,
    checkoutUserType,
    promotionClickDatas,
    promoCode,
  } = tealiumCheckoutHelper(cartData, algoliaProductAddedToCart, isLoggedIn)

  const utagObj = [
    'link',
    {
      tealium_event: 'add_shipping_info',
      available_points: available_points,
      checkout_step: 2,
      current_tier: currentTier,
      checkout_shipmethod: shippingMethods,
      checkout_shiptype: shipTypes,
      product_shippingoption: shippingMethods,
      checkout_usertype: checkoutUserType,
      handling_fee: handlingFees,
      order_grand_total,
      order_subtotal: [getCartSubTotal(cartData)],
      page_url: pageUrl,
      product_brand: productBrands,
      product_category: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[0]?.label ?? undefined),
      product_category2: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[1]?.label ?? undefined),
      product_category3: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[2]?.label ?? undefined),
      product_color: productVariant,
      product_discount_amount: productDiscounts,
      product_id: productIds,
      product_impression_position: product_impression_position,
      product_list: productList,
      product_list_price: productListprice,
      product_name: productNames,
      product_quantity: product_quantity,
      product_size: productSize,
      product_sku: productSkus,
      product_unit_price: productUnitprice,
      product_variant: productVariant,
      promo_code: promoCode || couponCode,
      promotion_id: promotionClickDatas.map((item) => item?.id || ''),
      promotion_name: promotionClickDatas.map((item) => item?.name),
      user_loginstatus: isLoggedIn,
      user_mystore: displayStoreNumber,
      page_type: 'checkout',
      site_id: 'dxl',
      hashed_email: userDetails?.email || undefined,
      customer_email: userDetails?.email || undefined,
      customer_postal_code: userDetails?.addresses?.[0]?.postalCode || undefined,
      customer_id: userDetails?.accountId || undefined,
      crm_customer_number: userDetails?.externalId || undefined,
    },
  ]
  utagLink(utagObj)
}
const utagCheckoutAddPaymentInfo = ({
  isLoggedIn,
  userDetails,
  cartData,
  pageUrl,
  displayStoreNumber,
  algoliaProductAddedToCart,
  userSessionData,
  couponCode,
  selectedShippingMethod,
  payments,
  available_points,
  currentTier,
}: {
  cartData?: Cart
  isLoggedIn: boolean
  userDetails: Account
  pageUrl: string
  displayStoreNumber: string
  algoliaProductAddedToCart: any
  userSessionData: Account
  couponCode: string
  selectedShippingMethod: ShippingMethod
  payments: string[]
  available_points: string
  currentTier: string
}) => {
  // const isLoggedIn = !!userSessionData?.firstName
  const { shippingMethods, shipTypes } = tealiumShippingMethodHelper(selectedShippingMethod, cartData)
  const {
    productBrands,
    productNames,
    product_impression_position,
    productBreadCrumbs,
    productDiscounts,
    productIds,
    productList,
    product_quantity,
    productSize,
    productVariant,
    productSkus,
    productListprice,
    productUnitprice,
    order_grand_total,
    handlingFees,
    checkoutUserType,
    promotionClickDatas,
    promoCode,
  } = tealiumCheckoutHelper(cartData, algoliaProductAddedToCart, isLoggedIn)

  const utagObj = [
    'link',
    {
      tealium_event: 'add_payment_info',
      available_points: available_points,
      checkout_paymenttype: payments,
      checkout_step: 3,
      current_tier: currentTier,
      checkout_shipmethod: shippingMethods,
      checkout_shiptype: shipTypes,
      product_shippingoption: shippingMethods,
      checkout_usertype: checkoutUserType,
      handling_fee: handlingFees,
      order_grand_total,
      order_subtotal: [getCartSubTotal(cartData)],
      page_url: pageUrl,
      product_brand: productBrands,
      product_category: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[0]?.label ?? undefined),
      product_category2: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[1]?.label ?? undefined),
      product_category3: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[2]?.label ?? undefined),
      product_color: productVariant,
      product_discount_amount: productDiscounts,
      product_id: productIds,
      product_impression_position: product_impression_position,
      product_list: productList,
      product_list_price: productListprice,
      product_name: productNames,
      product_quantity: product_quantity,
      product_size: productSize,
      product_sku: productSkus,
      product_unit_price: productUnitprice,
      product_variant: productVariant,
      promo_code: promoCode || couponCode,
      promotion_id: promotionClickDatas.map((item) => item?.id || ''),
      promotion_name: promotionClickDatas.map((item) => item?.name),
      user_loginstatus: isLoggedIn,
      user_mystore: displayStoreNumber,
      site_id: 'dxl',
      hashed_email: userDetails?.email || undefined,
      customer_email: userDetails?.email || undefined,
      customer_postal_code: userDetails?.addresses?.[0]?.postalCode || undefined,
      customer_id: userDetails?.accountId || undefined,
      crm_customer_number: userDetails?.externalId || undefined,
    },
  ]
  utagLink(utagObj)
}
const utagViewCheckoutStep3 = ({
  isLoggedIn,
  userDetails,
  cartData,
  pageUrl,
  displayStoreNumber,
  algoliaProductAddedToCart,
  userSessionData,
  couponCode,
  selectedShippingMethod,
  payments,
  available_points,
  currentTier,
  totalItems,
}: {
  cartData?: Cart
  isLoggedIn: boolean
  userDetails: Account
  pageUrl: string
  displayStoreNumber: string
  algoliaProductAddedToCart: any
  userSessionData: Account
  couponCode: string
  selectedShippingMethod: ShippingMethod
  payments: string[]
  available_points: string
  currentTier: string
  totalItems: number
}) => {
  // const isLoggedIn = !!userSessionData?.firstName
  const { shippingMethods, shipTypes } = tealiumShippingMethodHelper(selectedShippingMethod, cartData)
  const {
    productBrands,
    productNames,
    product_impression_position,
    productBreadCrumbs,
    productDiscounts,
    productIds,
    productList,
    product_quantity,
    productSize,
    productVariant,
    productSkus,
    productListprice,
    productUnitprice,
    order_grand_total,
    currency_code,
    country_code,
    promotionClickDatas,
    promoCode,
    productAlgoliaQueryIds,
    handlingFees,
    taxes,
  } = tealiumCheckoutHelper(cartData, algoliaProductAddedToCart, isLoggedIn)
  const utagObj = {
    product_category: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[0]?.label ?? undefined),
    product_category2: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[1]?.label ?? undefined),
    product_category3: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[2]?.label ?? undefined),
    product_list: productList,
    product_impression_position: product_impression_position,
    handling_fee: handlingFees,
    cart_total_items: totalItems,
    cart_total_value: order_grand_total,
    site_breadcrumb: undefined,
    checkout_paymenttype: payments,
    cm_category_id: 'checkout',
    cm_page_id: 'review',
    page_name: 'Checkout > review',
    page_category: 'checkout',
    page_type: 'checkout',
    available_points: available_points,
    current_tier: currentTier,
    page_url: pageUrl,
    checkout_shipmethod: shippingMethods,
    checkout_shiptype: shipTypes,
    product_shippingoption: shippingMethods,
    promotion_id: promotionClickDatas.map((item) => item?.id || ''),
    promotion_name: promotionClickDatas.map((item) => item?.name),
    potential_order_id: '',
    product_color: productVariant,
    product_variant: productVariant,
    product_id: productIds,
    product_name: productNames,
    product_quantity: product_quantity,
    product_size: productSize,
    product_sku: productSkus,
    product_list_price: productListprice,
    product_unit_price: productUnitprice,
    product_discount_amount: productDiscounts,
    promo_code: promoCode || couponCode,
    site_section: undefined,
    order_grand_total,
    order_subtotal: [getCartSubTotal(cartData)],
    order_tax_amount: taxes,
    order_discount_amount: cartData?.totalDiscountOnTotalPrice,
    user_loginstatus: isLoggedIn,
    user_mystore: displayStoreNumber,
    user_sizeprofile: 'off',
    product_brand: productBrands,
    product_algolia_query_id: productAlgoliaQueryIds,
    customer_email: userDetails?.email || cartData?.email,
  }
  const utagData = constructUtagData(pageUrl, userDetails, userDetails, utagObj)
  utagView(utagData, country_code, currency_code)
}

const utagCheckoutFinalPurchase = ({
  isLoggedIn,
  userDetails,
  cartData,
  displayStoreNumber,
  algoliaProductAddedToCart,
  userSessionData,
  couponCode,
  selectedShippingMethod,
  payments,
  available_points,
  currentTier,
  algoliaQueryId,
}: {
  cartData?: Cart
  isLoggedIn: boolean
  userDetails: Account
  displayStoreNumber: string
  algoliaProductAddedToCart: any
  userSessionData: Account
  couponCode: string
  selectedShippingMethod: ShippingMethod
  payments: string[]
  available_points: string
  currentTier: string
  algoliaQueryId: string
}) => {
  // const isLoggedIn = !!userSessionData?.firstName
  const { shippingMethods, shipTypes } = tealiumShippingMethodHelper(selectedShippingMethod, cartData)
  const {
    productBrands,
    productNames,
    product_impression_position,
    productBreadCrumbs,
    productDiscounts,
    productIds,
    productList,
    product_quantity,
    productSize,
    productVariant,
    productSkus,
    productListprice,
    productUnitprice,
    order_grand_total,
    handlingFees,
    checkoutUserType,
    currency_code,
    country_code,
    promotionClickDatas,
    promoCode,
    totalDiscountOnTotalPrice,
    shippingPrice,
    subtotal,
    taxes,
    attentiveIds,
  } = tealiumCheckoutHelper(cartData, algoliaProductAddedToCart, isLoggedIn)
  const utagObj = [
    'view',
    {
      tealium_event: 'purchase',
      available_points: available_points,
      country_code: country_code,
      currency_code: currency_code,
      customer_first_name: userDetails?.firstName || cartData?.billingAddress?.firstName,
      customer_last_name: userDetails?.lastName || cartData?.billingAddress?.lastName,
      order_discount_amount: totalDiscountOnTotalPrice,
      order_id: cartData?.orderNumber,
      order_shipping_amount: shippingPrice,
      order_subtotal: subtotal,
      order_tax_amount: taxes,
      order_type: 'default',
      page_category: 'checkout',
      page_type: 'receipt',
      page_url: '/checkout/success',
      checkout_paymenttype: payments,
      checkout_step: 3,
      current_tier: currentTier,
      checkout_shipmethod: shippingMethods,
      checkout_shiptype: shipTypes,
      product_shippingoption: shippingMethods,
      checkout_usertype: checkoutUserType,
      handling_fee: handlingFees,
      order_grand_total,
      product_brand: productBrands,
      product_category: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[0]?.label ?? undefined),
      product_category2: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[1]?.label ?? undefined),
      product_category3: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[2]?.label ?? undefined),
      product_color: productVariant,
      product_discount_amount: productDiscounts,
      product_id: productIds,
      attentiveId: attentiveIds,
      product_impression_position: product_impression_position,
      product_list: productList,
      product_list_price: productListprice,
      product_name: productNames,
      product_quantity: product_quantity,
      product_size: productSize,
      product_sku: productSkus,
      product_unit_price: productUnitprice,
      product_variant: productVariant,
      promo_code: promoCode || couponCode,
      promotion_id: promotionClickDatas.map((item) => item?.id || ''),
      promotion_name: promotionClickDatas.map((item) => item?.name),
      user_loginstatus: isLoggedIn,
      user_mystore: displayStoreNumber,
      site_id: 'dxl',
      hashed_email: userDetails?.email || cartData?.email || undefined,
      customer_email: userDetails?.email || cartData?.email || undefined,
      customer_postal_code:
        userDetails?.addresses?.[0]?.postalCode || cartData?.billingAddress?.postalCode || undefined,
      customer_id: userDetails?.accountId || undefined,
      crm_customer_number: userDetails?.externalId || undefined,
      product_algolia_query_id: algoliaQueryId,
    },
  ]
  utagView(utagObj, country_code, currency_code)
}
const utagViewOrderConfirmationPage = ({
  isLoggedIn,
  userDetails,
  cartData,
  displayStoreNumber,
  algoliaProductAddedToCart,
  userSessionData,
  couponCode,
  payments,
  available_points,
  currentTier,
  algoliaQueryId,
  pageUrl,
}: {
  cartData?: Cart
  isLoggedIn: boolean
  userDetails: Account
  displayStoreNumber: string
  algoliaProductAddedToCart: any
  userSessionData: Account
  couponCode: string
  payments: string[]
  available_points: string
  currentTier: string
  algoliaQueryId: string
  pageUrl: string
}) => {
  // const isLoggedIn = !!userSessionData?.firstName
  const {
    productBrands,
    productNames,
    product_impression_position,
    productBreadCrumbs,
    productDiscounts,
    productIds,
    productList,
    product_quantity,
    productSize,
    productVariant,
    productSkus,
    productListprice,
    productUnitprice,
    order_grand_total,
    handlingFees,
    checkoutUserType,
    currency_code,
    country_code,
    promotionClickDatas,
    promoCode,
    totalDiscountOnTotalPrice,
    shippingPrice,
    subtotal,
    productAlgoliaQueryIds,
    taxes,
    totalItems,
    attentiveIds,
  } = tealiumCheckoutHelper(cartData, algoliaProductAddedToCart, isLoggedIn)
  const utagObj = {
    page_category: 'checkout',
    checkout_usertype: checkoutUserType,
    cm_page_id: 'Confirmation',
    cm_category_id: 'Confirmation',
    isThirdParty: 'true',
    page_type: 'checkout',
    attentiveId: attentiveIds,

    page_name: 'Checkout Confirmation',
    order_id: cartData?.orderNumber,
    order_shipping_amount: shippingPrice,
    customer_first_name: userDetails?.firstName || cartData?.billingAddress?.firstName,
    customer_last_name: userDetails?.lastName || cartData?.billingAddress?.lastName,
    order_subtotal: subtotal,

    site_sub_section: '',
    site_sub_section_level3: '',
    site_sub_section_level4: '',
    customer_postal_code: userDetails?.addresses?.[0]?.postalCode || undefined,
    customer_id: userDetails?.accountId || undefined,
    crm_customer_number: userDetails?.externalId || undefined,

    product_category: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[0]?.label ?? undefined),
    product_category2: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[1]?.label ?? undefined),
    product_category3: productBreadCrumbs.map((breadCrumb) => breadCrumb?.[2]?.label ?? undefined),
    product_impression_position: product_impression_position,
    handling_fee: handlingFees,
    cart_total_items: totalItems,
    cart_total_value: order_grand_total,
    site_breadcrumb: undefined,
    checkout_paymenttype: payments,
    available_points: available_points,
    current_tier: currentTier,
    page_url: pageUrl,
    promotion_id: promotionClickDatas.map((item) => item?.id || ''),
    promotion_name: promotionClickDatas.map((item) => item?.name),
    potential_order_id: '',
    product_color: productVariant,
    product_variant: productVariant,
    product_id: productIds,
    product_name: productNames,
    product_quantity: product_quantity,
    product_size: productSize,
    product_sku: productSkus,
    product_list_price: productListprice,
    product_unit_price: productUnitprice,
    product_discount_amount: productDiscounts,
    promo_code: promoCode || couponCode,
    site_section: 'Order Confirmation',
    order_grand_total,
    order_tax_amount: taxes,
    order_discount_amount: cartData?.totalDiscountOnTotalPrice,
    user_loginstatus: isLoggedIn,
    user_mystore: displayStoreNumber,
    user_sizeprofile: 'off',
    product_brand: productBrands,
    product_algolia_query_id: productAlgoliaQueryIds,
    customer_email: userDetails?.email || cartData?.email,
  }
  const utagData = constructUtagData(pageUrl, userDetails, userDetails, utagObj)
  utagView(utagData, country_code, currency_code)
  clpPromoClickData = []
  window.localStorage.setItem('clpPromoClickData', JSON.stringify(clpPromoClickData))
}

export {
  utagView,
  utagLink,
  getClickedPromoNames,
  promoClickUtagLinkEvent,
  constructUtagData,
  utagAddToCart,
  utagAddToWishlist,
  utagRemoveToCart,
  utagApplyCouponCode,
  utagCheckoutStep1,
  utagCheckoutAddPaymentInfo,
  utagCheckoutFinalPurchase,
  getSelectedPaymentType,
  utagViewCart,
  utagViewCheckoutStep2,
  utagCheckoutAddShippingMethod,
  utagViewCheckoutStep3,
  utagViewOrderConfirmationPage,
}
