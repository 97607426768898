export const useBorderFreeCurrencyConversion = (price: number) => {
  return price.toFixed(2)
}

export const dollarsToCents = function (dollars: number): number {
  if (isNaN(dollars)) {
    throw new Error('Input must be a valid number')
  }
  return Math.round(dollars * 100)
}
