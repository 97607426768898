import { useEffect } from 'react'
import Script, { handleClientScriptLoad } from 'next/script'
import { useBreakpointValue } from '@chakra-ui/react'
import { BRANCH_IO_SCRIPT_KEY, FUNCTIONAL_COOKIE_CATEGORY } from 'composable/components/general/constants'
import { useLocale } from 'frontastic/contexts/localeContext'
import { getScriptType } from '../../../helpers/utils/appendScript'

export const ThirdPartyScripts = () => {
  const { country } = useLocale()
  let borderFreeScriptUrl = ''
  const countryCode = country || 'US'

  const addBranchIOScript = () => {
    let text =
      '(function (b, r, a, n, c, h, _, s, d, k) {' +
      'if (!b[n] || !b[n]._q) {' +
      'for (; s < _.length;) c(h, _[s++]);' +
      'd = r.createElement(a);' +
      'd.async = 1;' +
      'd.src = "https://cdn.branch.io/branch-latest.min.js";' +
      'k = r.getElementsByTagName(a)[0];' +
      'k.parentNode.insertBefore(d, k);' +
      'b[n] = h' +
      '}' +
      '})(window, document, "script", "branch", function (b, r) {' +
      'b[r] = function () {' +
      'b._q.push([r, arguments])' +
      '}' +
      '}, {' +
      '_q: [],' +
      '_v: 1' +
      '}, "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);'

    text += "branch.init('" + BRANCH_IO_SCRIPT_KEY + "');"
    return text
  }

  useEffect(() => {
    handleClientScriptLoad({
      children: addBranchIOScript(),
      type: getScriptType(FUNCTIONAL_COOKIE_CATEGORY),
      id: 'branchIOScript',
      className: FUNCTIONAL_COOKIE_CATEGORY,
    })
    addBranchIOScript()
  }, [])

  return (
    <>
      <Script
        async
        src="https://code.jquery.com/jquery-3.7.1.min.js"
        id="jQuery-script"
        type="text/javascript"
      ></Script>
    </>
  )
}
