import { extendTheme } from '@chakra-ui/react'
import Alert from './theme/components/alert'
import AlertDialog from './theme/components/alertDialog'
import Badge from './theme/components/badge'
import { Button } from './theme/components/button'
import { Checkbox } from './theme/components/checkbox'
import CloseButton from './theme/components/closeButton'
import Divider from './theme/components/divider'
import IconButton from './theme/components/iconButton'
import Input from './theme/components/input'
import Progress from './theme/components/progress'
import Radio from './theme/components/radio'
import Select from './theme/components/select'
import Tabs from './theme/components/tabs'
import Tag from './theme/components/tag'
import Tooltip from './theme/components/tooltip'
import { foundations } from './theme/foundations'
import { colors } from './theme/foundations/colors'
import { semanticTokens } from './theme/foundations/semanticTokens'
import { layerStyles } from './theme/layerStyles'
import { styles } from './theme/styles'
import { textStyles } from './theme/textStyles'

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-26px)',
}

const components = {
  AlertDialog,
  Alert,
  Badge,
  CloseButton,
  Divider,
  IconButton,
  Input,
  Progress,
  Tabs,
  Tag,
  Tooltip,
  Button,
  Checkbox,
  Radio,
  Select,
}

export const theme = extendTheme({
  ...foundations,
  textStyles, // text variants
  layerStyles, // style variants
  styles, // Global style override
  colors,
  semanticTokens,
  components: {
    ...components,
    ...{
      Form: {
        variants: {
          floating: {
            container: {
              _focusWithin: {
                label: {
                  ...activeLabelStyles,
                },
              },
              'input:not(:placeholder-shown) + label': {
                ...activeLabelStyles,
              },
              label: {
                top: 0,
                left: 0,
                zIndex: 111,
                position: 'absolute',
                backgroundColor: 'white',
                pointerEvents: 'none',
                mx: 3,
                px: 1,
                my: 4,
                transformOrigin: 'left top',
              },
            },
          },
        },
      },
    },
  },
})
